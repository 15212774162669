import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller, useFieldArray } from "react-hook-form";
import UI from "../../../../@components/UI";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Poll = ({ control, errors, setValue }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { fields, append, remove, replace } = useFieldArray({
    control: control,
    name: `content.options`,
  });

  React.useEffect(() => {
    // Default values
    setValue("content", {
      type: "poll",
      title: "",
      options: [],
      multiple_select: false,
      datetime_expire: new Date(Date.now() + 24 * 60 * 60 * 1000), // 24 hours from now
    });

    replace([
      { id: 1, title: "" },
      { id: 2, title: "" },
    ]);
  }, [setValue, replace]);

  return (
    <Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px", mt: "20px" }}>
        <Typography variant={"h6"}>
            {t("brands.feed.create.poll.title.title")}
          </Typography>
        </Box>
        <Controller
          name={"content.title"}
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <UI.TextField
              placeholder={t("brands.feed.create.poll.title.placeholder")}
              {...field}
            />
          )}
        />
        {errors.content?.title?.type === "required" && (
          <Box sx={{ mt: "15px" }}>
            <Alert
              variant="outlined"
              severity="error"
            >
              {t("brands.feed.create.poll.title.error.required")}
            </Alert>
          </Box>
        )}
      </Box>
      <Box className={classes.inputOuter} sx={{ mb: "12px !important" }}>
        <Box sx={{ display: "flex", mb: "10px" }}>
        <Typography variant={"h6"}>
            {t("brands.feed.create.poll.options.title")}
          </Typography>
        </Box>
        {fields.map((item, index) => {
          return (
            <Box sx={{ display: "flex", mb: "12px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Controller
                  name={`content.options.${index}.title`}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      placeholder={t(
                        "brands.feed.create.poll.options.placeholder",
                        {
                          index: index + 1,
                        }
                      )}
                      {...field}
                    />
                  )}
                />
                {Object.keys(errors).length > 0 &&
                  errors.content?.options &&
                  errors.content.options[index]?.title?.type === "required" && (
                    <Box sx={{ mt: "15px" }}>
                      <Alert
                        variant="outlined"
                        severity="error"
                      >
                        {t("brands.feed.create.poll.options.error.required")}
                      </Alert>
                    </Box>
                  )}
              </Box>
              {index > 1 && (
                <Box
                  onClick={() => remove(index)}
                  sx={{
                    cursor: "pointer",
                    ml: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme.palette.colors.darkRed,
                      opacity: "0.8",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          );
        })}
        <Box
          onClick={() => {
            fields.length < 10 && append({ id: fields.length + 1, title: "" });
          }}
          variant="raised"
          component="span"
          sx={{
            cursor: fields.length < 10 ? "pointer" : "cursor",
            padding: { md: "15px", mobile: "0px" },
            background: (theme) => theme.palette.bgTransparent.primary,
            borderRadius: (theme) => theme.borderRadius.secondary,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "55px",
            transition: "0.2s all ease",
            "&:hover": {
              background: (theme) => theme.palette.bgTransparent.secondary,
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "flex-start" }}>
            <AddIcon
              sx={{
                color: fields.length < 10 ? "#944DFF" : "#944dff8f",
                fontSize: "2.4rem",
              }}
            />
            <Box
              sx={{
                ml: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  color:
                    fields.length < 10
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(255, 255, 255, 0.5)",
                }}
                variant={"h8"}
              >
                {t("brands.feed.create.poll.options.add")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.inputOuter} sx={{ mb: "0px !important" }}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Controller
            name={"content.multiple_select"}
            control={control}
            render={({ field }) => (
              <UI.Checkbox
                {...field}
                nomargin={"true"}
                label={
                  <Box
                    component={"span"}
                    sx={{ fontWeight: "700", textTransform: "uppercase", fontSize: "14px" }}
                  >
                    {t("brands.feed.create.poll.allowMultiple")}
                  </Box>
                }
              />
            )}
          />
        </Box>
      </Box>
      <Box className={classes.inputOuter} sx={{ mt: "5px !important", mb: "-5px !important" }}>
        <Box sx={{ display: "flex", mb: "10px" }}>
        <Typography variant={"h6"}>
            {t("brands.feed.create.poll.duration")}
          </Typography>
        </Box>
        <Controller
          name={"content.datetime_expire"}
          control={control}
          render={({ field }) => <UI.DateTime {...field} />}
        />
      </Box>
    </Box>
  );
};

export default Poll;
