import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const Item = () => {
  return (
    <Grid
      item
      mobile={20}
      tablet={6.6}
      md={6.6}
      lg={4}
      xxl={3.3}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          textAlign: "left",
          margin: "0 auto",
          width: "290px",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          border: "2px solid transparent",
          transition: "all 0.2s ease-in-out",
        }}
      >
        <Skeleton variant={"rectangular"} width={"100%"} height={"430px"} />
      </Box>
    </Grid>
  );
};

const CardSkeletonList = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return (
    <>
      {items.map((index) => (
        <Item key={index} />
      ))}
    </>
  );
};

export default CardSkeletonList;
