import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import cardNewButtonIcon from "../../../../../../static/card-new-icon.png";
import DropdownMenu from "./DropdownMenu";

const AddNewCard = ({ item }) => {
  const [openMenu, setOpenMenu] = React.useState(null);
  const { t } = useTranslation();

  return (
    <>
      <Box
        onClick={(e) => {
          setOpenMenu(e.currentTarget);
        }}
        key={item.id}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          boxShadow: (theme) => theme.palette.album.boxShadow,
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
            transform: "scale(1.02)",
            cursor: "pointer",
          },
        }}
      >
        <Box
          component={"img"}
          sx={{
            width: { md: "50px", mobile: "45px" },
            height: { md: "50px", mobile: "45px" },
            mb: { md: "5px", mobile: "6px" },
            ml: { md: "2px", mobile: "3px" },
            filter: (theme) =>
              theme.palette.type === "dark" ? "none" : "invert(1)",
          }}
          src={cardNewButtonIcon}
        />
        <div>
          <Typography
            variant={"h8"}
            sx={{
              lineHeight: { mobile: "1.2", md: "1.3" },
              mt: { tablet: "24px", mobile: "20px" },
              fontSize: { tablet: "14px", mobile: "10px" },
              display: "flex",
              textAlign: "center",
              px: { md: "30px", mobile: "10px" },
            }}
          >
            {t("brands.albums.addNewCard")}
          </Typography>
        </div>
      </Box>
      <DropdownMenu openMenu={openMenu} onClose={() => setOpenMenu(null)} />
    </>
  );
};
export default AddNewCard;
