import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useUserContext } from "../../../../../../../@components/userContext";
import useService from "../../../../useService";
import CustomHooks from "../../../../../../../@components/hooks";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Video = ({ DAO }) => {
  const { albumId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const { mutate: createVideo } = CustomHooks.usePost(service.newCard, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.cards.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album/${albumId}/edit`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      video: "",
    },
  });

  const onSubmit = async (form) => {
    const data = {
      name: "Video",
      album_space_type: "Video",
      data_json: {
        video_url: form.video.ipfs,
        card_image: form.card_image,
      },
      card_image: form.card_image,
    };

    createVideo({ albumId: albumId, data: data });
  };
  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.bgTransparent.primary, mx: { lg: "80px", md: "50px", tablet: "15px", mobile: "10px" }, mt: { md: "50px", mobile: "0" }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { lg: "80px", md: "50px", tablet: "30px", mobile: "10px" },
            pb: "50px",
            pt: { lg: "60px", md: "50px", tablet: "40px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbum")}
            onClick={() => history.go(-1)}
          />

          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "30px",
            }}
          >
            {t("brands.albums.createCard.video.createVideoCard")}
          </Typography>
          <Controller
            name={"video"}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UI.Upload
                noHeight
                noPadding
                {...field}
                id={"video"}
                title={t("brands.albums.createCard.video.upload.title")}
                subtitle={t("brands.albums.createCard.video.upload.subtitle")}
                acceptFiles={"video/mp4"}
              />
            )}
          />
          <Box sx={{ mt: "15px" }}>
            {errors.video?.type === "required" && (
              <Alert variant="outlined" severity="error">
                {t("brands.albums.createCard.error.video")}
              </Alert>
            )}
          </Box>
          <Box sx={{ display: "flex", mb: "20px", mt: "30px" }}>
            <Typography variant={"h6"}>
              {t("brands.albums.createCard.backImage.UploadCardBackImage")}
            </Typography>
            <Typography variant="subtitle3" sx={{ ml: "5px", mt: "2px" }}>
              {t("commons.optional")}
            </Typography>
          </Box>
          <Controller
            name={"card_image"}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <UI.Upload
                noHeight
                noPadding
                aiButtonEnabled
                {...field}
                id={"card_image"}
                title={t("brands.albums.createCard.image.upload.title")}
                subtitle={t(
                  "brands.collection.create.basicInfo.uploadPhoto.subtitle"
                )}
                acceptFiles={"image/jpg, image/jpeg,image/gif,image/png"}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px", mb: "60px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("brands.albums.createCard.button.createCard")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Video;
