import React from "react";
import Container from "@mui/material/Container";
import CardList from "../CardList";
import SmallList from "../SmallList";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import useCollectionService from "../useService";
import CustomHooks from "../../@components/hooks";
import UI from "../../@components/UI";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [display, setDisplay] = React.useState("big");

  React.useEffect(() => {
    if (isMobile) {
      setDisplay("small");
    } else {
      setDisplay("big");
    }
  }, [isMobile]);

  const collectionService = useCollectionService();

  const { data: items, isLoading: collectionsLoading } = CustomHooks.useFetch(
    ["collections_explore"],
    collectionService.list
  );

  const handleDisplay = (event, newDisplay) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        pr: { lg: "100px", md: "50px", tablet: "20px", mobile: "10px" },
        pl: { lg: "110px", md: "110px", tablet: "20px", mobile: "10px" },
        mb: "50px",
        mt: { mobile: "0px", md: "40px" } 
      }}
    >
      <Typography sx={{ display: { mobile: "flex", md: "none" } }} variant="h2">
        {t("appBar.collections")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <ToggleButtonGroup
          value={display}
          exclusive
          onChange={handleDisplay}
          aria-label="item display"
        >
          <ToggleButton
            value="big"
            aria-label="big display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewModuleIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
          <ToggleButton
            value="small"
            aria-label="small display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewListIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {display === "big" && (
        <CardList
          items={items}
          busy={collectionsLoading}
          limit={{ from: 0, to: 50 }}
        />
      )}
      {display === "small" && (
        <Box>
          {isMobile && items && items.length !== 0 && (
            <Box sx={{ mb: "46px" }}>
              <UI.SwiperMobile items={items} from={0} to={10} />
            </Box>
          )}
          <SmallList
            items={items}
            limit={{ from: isMobile ? 10 : 0, to: 50 }}
            busy={collectionsLoading}
          />
        </Box>
      )}
    </Container>
  );
};

Index.List = SmallList;

export default Index;
