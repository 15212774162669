import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import UI from "../../../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useUserContext } from "../../../../../../../@components/userContext";
import useService from "../../../../useService";
import CustomHooks from "../../../../../../../@components/hooks";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Discount = ({ DAO }) => {
  const { t } = useTranslation();
  const { albumId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const { mutate: createDiscount } = CustomHooks.usePost(service.newCard, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.cards.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album/${albumId}/edit`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      value: "",
      code: "",
      description: "",
      external_link: "",
    },
  });

  const onSubmit = async (form) => {
    const data = {
      name: "Spatial",
      album_space_type: "Spatial",
      data_json: {
        ...form,
      },
      card_image: form.card_image.ipfs,
    };
    await createDiscount({ albumId: albumId, data: data });
  };
  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.bgTransparent.primary, mx: { lg: "80px", md: "50px", tablet: "15px", mobile: "10px" }, mt: { md: "50px", mobile: "0" }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { lg: "80px", md: "50px", tablet: "30px", mobile: "10px" },
            pb: "50px",
            pt: { lg: "60px", md: "50px", tablet: "40px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbum")}
            onClick={() => history.go(-1)}
          />

          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "30px",
            }}
          >
            {t("brands.albums.createCard.spatial.createSpatialCard")}
          </Typography>
          <Controller
            name={"image"}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UI.Upload
                noHeight
                noPadding
                aiButtonEnabled
                {...field}
                id={"images"}
                title={t("brands.albums.createCard.image.upload.title")}
                subtitle={t(
                  "brands.collection.create.basicInfo.uploadPhoto.subtitle"
                )}
                acceptFiles={"image/jpg, image/jpeg,image/gif,image/png"}
              />
            )}
          />
          <Box sx={{ mt: "15px", mb: "15px" }}>
            {errors.image?.type === "required" && (
              <Alert variant="outlined" severity="error">
                {t("brands.albums.createCard.error.image")}
              </Alert>
            )}
          </Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "6px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.createCard.spatial.name")}
                  </Typography>
                </Box>
                <Controller
                  name={"name"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField aiButtonEnabled {...field} />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.name?.type === "required" && (
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.createCard.error.name")}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "6px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.createCard.spatial.description")}
                  </Typography>
                </Box>
                <Controller
                  name={"description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      multiline
                      rows={5}
                      aiButtonEnabled
                      {...field}
                    />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.description?.type === "required" && (
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.createCard.error.description")}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "6px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.createCard.spatial.spatialLink")}
                  </Typography>
                </Box>
                <Controller
                  name={"external_link"}
                  rules={{
                    required: true,
                    pattern: {
                      value:
                        /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g,
                      message: "Invalid URL",
                    },
                  }}
                  control={control}
                  render={({ field }) => <UI.TextField {...field} />}
                />
                {errors.external_link?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.createCard.error.externalLink")}
                    </Alert>
                  </Box>
                )}
                {errors?.external_link?.type === "pattern" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {errors?.external_link.message}
                    </Alert>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", mb: "20px", mt: "30px" }}>
                <Typography variant={"h6"}>
                  {t("brands.albums.createCard.backImage.UploadCardBackImage")}
                </Typography>
                <Typography variant="subtitle3" sx={{ ml: "5px", mt: "2px" }}>
                  {t("commons.optional")}
                </Typography>
              </Box>
              <Controller
                name={"card_image"}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <UI.Upload
                    noHeight
                    noPadding
                    aiButtonEnabled
                    {...field}
                    id={"card_image"}
                    title={t("brands.albums.createCard.image.upload.title")}
                    subtitle={t(
                      "brands.collection.create.basicInfo.uploadPhoto.subtitle"
                    )}
                    acceptFiles={"image/jpg, image/jpeg,image/gif,image/png"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px", mb: "60px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("brands.albums.createCard.button.createCard")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Discount;
