import React from "react";
import CustomHooks from "../@components/hooks";
import useService from "./useService";
import CardList from "./CardList";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const List = () => {
  const service = useService();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [display, setDisplay] = React.useState("big");

  React.useEffect(() => {
    if (isMobile) {
      setDisplay("small");
    } else {
      setDisplay("big");
    }
  }, [isMobile]);

  const handleDisplay = (event, newDisplay) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };
  const { data, isLoading: loading } = CustomHooks.useFetch(
    ["marketplace"],
    service.marketplace,
    {
      fetchPolicy: "network-only",
    }
  );

  return (
    <>
      <Typography sx={{ display: { mobile: "flex", md: "none" } }} variant="h2">
        {t("appBar.collections")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <ToggleButtonGroup
          value={display}
          exclusive
          onChange={handleDisplay}
          aria-label="item display"
        >
          <ToggleButton
            value="big"
            aria-label="big display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewModuleIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
          <ToggleButton
            value="small"
            aria-label="small display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewListIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <CardList
        items={data?.results || []}
        busy={loading}
        limit={{ from: 0, to: 50 }}
      />
    </>
  );
};

export default List;
