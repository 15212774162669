import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const Item = () => {
  return (
    <Grid
      item
      mobile={12}
      tablet={6}
      md={6}
      lg={4}
      xl={4}
      xxl={3}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          textAlign: "left",
          margin: "0 auto",
          width: "600px",
          height: "150px",
          display: "flex",
          borderRadius: "10px",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Skeleton variant={"rectangular"} width={"100%"}/>
      </Box>
    </Grid>
  );
};

const AlbumSkeletonList = () => {
  const items = [1, 2, 3, 4, 5, 6];
  return (
    <>
      {items.map((index) => (
        <Item key={index} />
      ))}
    </>
  );
};

export default AlbumSkeletonList;
