import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../../@components/UI";
//import collectionsIcon from "../../../../../../static/group-nft-icon.png";
import collectionsIcon from "../../../../../../static/collections-icon.png";
import { useDrop } from "react-dnd";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const ConfirmedSpace = ({ item }) => {
  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.borderRadius.primary,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: "flex",*/}
      {/*    justifyContent: "center",*/}
      {/*    width: "100%",*/}
      {/*    height: { md: "50px", mobile: "32px" },*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Typography*/}
      {/*    variant={"h9"}*/}
      {/*    sx={{*/}
      {/*      p: "8px",*/}
      {/*      mt: "3px",*/}
      {/*      maxWidth: { md: "135px", mobile: "90px" },*/}
      {/*      display: "flex",*/}
      {/*      alignItems: "center",*/}
      {/*      fontSize: { md: "14px", mobile: "10px" },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <UI.OverflowTip>*/}
      {/*      {(item?.album_card.length !== 0 &&*/}
      {/*        item?.album_card[0]?.collection_name) ||*/}
      {/*        null}*/}
      {/*    </UI.OverflowTip>*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {item && item?.collections && (
          <UI.ImageWithSkeleton
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              transition: "all 0.3s ease-in-out",
            }}
            src={
              (item?.album_card.length !== 0 &&
                item?.album_card[0]?.image_url) ||
              null
            }
          />
        )}
      </Box>
    </Box>
  );
};

const UnconfirmedSpace = ({ display, item }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          display: display,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          border: "2px solid #2fd729",
          boxShadow: "#2fd729 0px 0px 10px 0px",
        }}
      >
        <Typography variant={"h8"} sx={{ color: "#ffffff" }}>
          {t("brands.albums.cards.dropHere")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <UI.ImageWithSkeleton
          sx={{
            width: { tablet: "100px", mobile: "68px" },
            height: { tablet: "100px", mobile: "68px" },
            transition: "all 0.3s ease-in-out",
          }}
          src={collectionsIcon}
        />
      </Box>
      <Typography
        variant={"h8"}
        sx={{
          position: "absolute",
          bottom: { lg: "30px", tablet: "18px", mobile: "5px" },
          lineHeight: { mobile: "1.2", md: "1.3" },
          mt: { tablet: "24px", mobile: "20px" },
          fontSize: { tablet: "14px", mobile: "10px" },
          display: "flex",
          textAlign: "center",
          px: { md: "30px", mobile: "10px" },
        }}
      >
        {t("brands.albums.cards.nftChoice")}
      </Typography>
    </>
  );
};

const Backside = ({ item, onDrop }) => {
  const collectionIds =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections.map((collection) => collection.id.toString());

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: collectionIds,
    drop: (data, nft) => {
      onDrop({ droppedItem: data, spaceItem: item });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const spaceIsConfirmed = item.album_card.length !== 0;
  const isLocked = item.is_locked;

  const isActive = isOver && canDrop && !isLocked;
  let display = "none";
  if (isActive) {
    display = "flex";
  } else if (canDrop && !isLocked) {
    display = "flex";
  }
  return (
    <>
      <Box
        ref={drop}
        key={item.id}
        sx={{
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          boxShadow: (theme) => theme.palette.album.boxShadow,
          position: "relative",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: spaceIsConfirmed ? "flex-start" : "center",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
            transform: "scale(1.025)",
          },
        }}
      >
        {!spaceIsConfirmed && !isLocked && !isOver && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000,
              borderRadius: (theme) => theme.borderRadius.secondary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              animation: "pulse 3s infinite",
              "@keyframes pulse": {
                "0%": {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  transform: "scale(1)"
                },
                "50%": {
                  backgroundColor: (theme) => theme.palette.album.background,
                  transition: "all ease-in",
                  transform: "scale(0.995)"
                },
                "100%": {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              },
            }}
          ></Box>
        )}
        {!spaceIsConfirmed && (
          <UnconfirmedSpace item={item} display={display} />
        )}
        {spaceIsConfirmed && <ConfirmedSpace item={item} display={display} />}
      </Box>
    </>
  );
};

const Action = ({ element: Component, item, onSelect }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const collectionIds =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections.map((collection) => collection.id);
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          !isMobile && onSelect([]);
        }}
      >
        <Box
          sx={{ cursor: "pointer", width: "100%", height: "100%" }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(collectionIds);
          }}
        >
          {Component}
        </Box>
      </ClickAwayListener>
    </>
  );
};

Backside.Action = Action;

export default Backside;
