import React, { useState } from "react";
import { Box, Grid, Typography, Link as MuiLink, Button } from "@mui/material";
import { Link } from "react-router-dom";

const BlogSection = () => {
  const newsData = [
    {
      category: "COLLECTOR'S ALBUM",
      date: "29 Aug 2024",
      title: "Exciting December on Qvrse",
      description: "Events, Missions, Christmas Village and more await this holiday season!",
      image: "https://christmas.qvrse.io/assets/img/xmas-blog.png",
      link: "https://blog.qvrse.io/2024/08/29/creating-christmas-campaign-with-qvrse/",
    },
    {
      category: "TECHNOLOGY",
      date: "22 July 2024",
      title: "Qvrse & Alchemy Integration",
      description: "Introducing new technologies can be a challenge if they are widely known.",
      image: "https://blog.qvrse.io/wp-content/uploads/2024/07/Qvrse-blog-720x380.png",
      link: "https://blog.qvrse.io/2024/07/22/qvrse-in-mnozicna-uporaba-web3-tehnologij/",
    },
    {
      category: "COLLECTOR'S ALBUM",
      date: "8 July 2024",
      title: "The Qvrse NFT Collector’s Album!",
      description: "Qvrse has introduced the Collector's Album for NFT enthusiasts.",
      image: "https://blog.qvrse.io/wp-content/uploads/2024/07/qvrse-album-izpostavitev-1-720x380.jpg",
      link: "https://blog.qvrse.io/2024/07/08/presenting-the-qvrse-nft-collectors-album-%f0%9f%93%9a%e2%9c%a8/",
    },
    {
      category: "NEW POST",
      date: "14 June 2024",
      title: "Qvrse premieres at Qvrse Experience Day",
      description: "The time has finally come for Qvrse to be unveiled - 4 July 2024 at 20:00 ...",
      image: "https://blog.qvrse.io/wp-content/uploads/2024/06/qvrseExperience-banner.jpg",
      link: "https://blog.qvrse.io/2024/06/14/qvrse-1-0-premierno-na-qvrse-experience-day/",
    },
    {
      category: "NFT certificate",
      date: "5 Apr 2024",
      title: "DIVINE KALY NFT CERTIFIED IMAGES",
      description: "Kaly Kolonič Potočnik introduces new energy paintings ...",
      image: "https://blog.qvrse.io/wp-content/uploads/2024/04/BeautyPlus_20240403183935710_save-720x380.jpg",
      link: "https://blog.qvrse.io/2024/04/05/divine-kaly-nft-certificirane-slike/",
    },
    {
      category: "The virtual world",
      date: "7 Feb 2024",
      title: "MRFYLAND RULES ON QVRSE",
      description: "MRFY CARD 2.0, which opens the door to an even better MRFYLAND ...",
      image: "https://blog.qvrse.io/wp-content/uploads/2024/02/Posnetek-zaslona-2024-02-07-211650-585x380.png",
      link: "https://blog.qvrse.io/2024/02/07/mrfyland-kraljuje-na-qvrsu/",
    },
    {
      category: "The virtual world",
      date: "30 Jan 2024",
      title: "TONY LAND GUESTS AT THE QVRSE ARENA",
      description: "Tony Cetinski will present his new AI spot in the most innovative way!",
      image: "https://blog.qvrse.io/wp-content/uploads/2024/01/TONY-CETINSKI-ADI-SOSE-SVE-ILI-NISTA-Screenshot-29-720x380.jpg",
      link: "https://blog.qvrse.io/2024/01/30/tony-land-gostuje-v-qvrse-areni/",
    },
    {
      category: "NFT souvenir",
      date: "26 Jan 2024",
      title: "1st NFT collection by Tony Cetinski",
      description: "Through the virtual party, you can choose Tony Cetinski hairstyle!",
      image: "https://blog.qvrse.io/wp-content/uploads/2024/01/cetinski.d9d12410-1.jpg",
      link: "https://blog.qvrse.io/2024/01/26/tony-hair/",
    },
  ];


  const [mainPost, setMainPost] = useState(newsData[0]);
  const sidePosts = newsData.filter((post) => post !== mainPost);

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        borderRadius: "20px",
        p: { md: "40px", xs: "20px" },
        mt: { md: "40px", xs: "20px" },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4, flexWrap: "wrap" }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, fontSize: { md: "24px", xs: "20px" } }}
        >
          Qvrse Blog
        </Typography>
        <MuiLink
          component={Link}
          href="https://blog.qvrse.io/"
          target="_blank"
          underline="none"
        >
          <Typography variant="body2" sx={{ fontWeight: 600, color: "#000" }}>
            View all posts →
          </Typography>
        </MuiLink>
      </Box>

      <Grid container spacing={4}>
        {/* Main Post */}
        <Grid item xs={12} md={8}>
          <Box sx={{ borderRadius: "20px", backgroundColor: "#fff", pb: 2 }}>
            <Box sx={{ position: "relative" }}>
              <img
                src={mainPost.image}
                alt={mainPost.title}
                style={{ width: "100%", display: "block" }}
              />
            </Box>
            <Box sx={{ p: { md: "24px", xs: "16px" } }}>
              <Typography
                variant="body2"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  color: "#C15A2E",
                  mb: 1,
                }}
              >
                {mainPost.category || "CATEGORY"}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  mb: 2,
                  fontSize: { md: "28px", xs: "22px" },
                }}
              >
               <a  href={mainPost.link}
               className="a-link"
                    target="_blank"> {mainPost.title}</a>
              </Typography>
              <Typography variant="body2" sx={{ color: "#555", mb: 2 }}>
                {mainPost.description}
              </Typography>
        
            </Box>
          </Box>
        </Grid>

        {/* Side Posts */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {sidePosts.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  p: "10px",
                  cursor: "pointer",
                  transition: "background 0.2s",
                  "&:hover": {
                    backgroundColor: "#F3EFEA",
                  },
                }}
                onClick={() => setMainPost(item)}
              >
                <Box sx={{ flex: "1 1 auto", pr: 2 }}>
                  <Typography
                    variant="overline"
                    sx={{
                      fontWeight: 700,
                      textTransform: "uppercase",
                      color: "#C15A2E",
                    }}
                  >
                    {item.category || "CATEGORY"}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      lineHeight: "1.3",
                      mt: 0.5,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexShrink: 0,
                    width: "80px",
                    height: "60px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BlogSection;
