import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import discountIcon from "../../../../../../static/discount-icon.png";
import React from "react";
import Discount from "./index";

const BacksideAction = ({ item, isEditing }) => {
  const spaceIsConfirmed = item.album_card.length !== 0;
  const isLocked = item.is_locked;
  return (
    <Box
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        boxShadow: (theme) => theme.palette.album.boxShadow,
        position: "relative",
        cursor: "pointer",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
          transform: "scale(1.025)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <UI.ImageWithSkeleton
          sx={{
            width: { tablet: "100px", mobile: "70px" },
            height: { tablet: "100px", mobile: "70px" },
            transition: "all 0.3s ease-in-out",
          }}
          src={discountIcon}
        />
      </Box>

      {!spaceIsConfirmed && !isLocked && !isEditing && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
            borderRadius: (theme) => theme.borderRadius.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            animation: "pulse 3s infinite",
            "@keyframes pulse": {
              "0%": {
                backgroundColor: "rgba(0, 0, 0, 0)",
                transform: "scale(1)"
              },
              "50%": {
                backgroundColor: (theme) => theme.palette.album.background,
                transition: "all ease-in",
                transform: "scale(0.995)"
              },
              "100%": {
                backgroundColor: "rgba(0, 0, 0, 0)",
              },
            },
          }}
        ></Box>
      )}
    </Box>
  );
};

const BackSide = ({ refetch, DAO, item, isEditing, triggerConfetti }) => {
  return (
    <Box
      sx={{ height: "100%", width: "100%" }}
      onClick={(e) => e.stopPropagation()}
    >
      <Discount.Dialog.Action
        refetch={refetch}
        data={item}
        albumSpace={item}
        DAO={DAO}
        triggerConfetti={triggerConfetti}
        element={<BacksideAction item={item} isEditing={isEditing} />}
      />
    </Box>
  );
};

BackSide.Action = BacksideAction;

export default BackSide;
