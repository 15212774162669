import React from "react";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Dialog from "@mui/material/Dialog";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useSnackbar } from "notistack";
import Busy from "./busy";
import { useTranslation } from "react-i18next";

// in this component we set IPFS up to host out nft data of file storage
const projectId = "2FJrR2634gDmft79seWtyfxalex";
const projectSecret = "7919990e44d516f4c2cbeb92b9df199a";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

const useStyles = makeStyles(() => ({
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  thumb: {
    display: "inline-flex",
    borderRadius: 5,
    border: "1px solid transparent",
    marginBottom: 10,
    marginRight: 8,
    width: 100,
    height: 100,
    marginLeft: 4,
    boxSizing: "border-box",
    position: "relative",
    "&:hover $overlay": {
      opacity: 1,
      border: "2px solid #944dff",
      boxShadow: "#944dff 0px 0px 10px 0px",
      transition: "all 0.3s ease-in-out",
    },
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%",
    borderRadius: "8px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: ".5s ease",
    borderRadius: 5,
    backgroundColor: "rgba(8, 10, 25, 0.7)",
  },

  text: {
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    position: "absolute",
    top: "50%",
    left: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    "-ms-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));
const maxFileSize = 25 * 1048576; // 25MB
const Dropzone = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    title,
    subtitle,
    onChange,
    acceptFiles,
    defaultValue,
    onDefaultValueSet,
  } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [showPreviewFile, setShowPreviewFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFiles || { "image/*": [] },
    onDrop: async (acceptedFiles) => {
      const filesWithIpfs = [];
      setUploading(true);
      for (const file of acceptedFiles) {
        // Create a Blob from the file's content
        const fileContentBlob = new Blob([file], { type: file.type });
        const ipfsUrl = await uploadToIpfs(fileContentBlob);
        filesWithIpfs.push(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            ipfs: ipfsUrl,
          })
        );
      }

      setFiles(filesWithIpfs);
      onChange(filesWithIpfs);
      setUploading(false);
    },
  });

  React.useEffect(() => {
    // go trough array and add preview url to each file which is the same as image_url
    if (defaultValue) {
      const filesWithPreview = defaultValue.map((file) => {
        return {
          type: file.file_type,
          preview: file.image_url,
          ipfs: file.image_url,
          name: file.name,
        };
      });
      setFiles(filesWithPreview);
      onDefaultValueSet(filesWithPreview);
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  const uploadToIpfs = async (file) => {
    if (file) {
      if (file.size > maxFileSize) {
        enqueueSnackbar(`The file is too large! Maximum file size: 25MB`, {
          variant: "error",
        });
      } else {
        try {
          const added = await client.add(file, {
            progress: (prog) => console.log(`recieved: ${prog}`),
          });

          const url = `https://hazellabs.infura-ipfs.io/ipfs/${added.path}`;

          return url;
        } catch (error) {
          enqueueSnackbar(error || "Error uploading file:", {
            variant: "error",
          });
        }
      }
    }
  };

  // React.useEffect(() => {
  //   files && !isSettingDefaultValue && onChange(files);
  // }, [files, onChange]);

  const thumbs = files.map((file) => (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={() => {
        setShowPreviewFile(file);
      }}
      className={classes.thumb}
      key={file.name}
    >
      {file?.type?.startsWith("image") && (
        <Box className={classes.thumbInner}>
          <Box component={"img"} src={file.preview} className={classes.img} />
        </Box>
      )}
      {file?.type?.startsWith("video") && (
        <Box className={classes.thumbInner}>
          <video
            autoPlay="autoplay"
            playsInline
            loop
            muted
            className={classes.img}
          >
            <source src={file.preview} />
          </video>
        </Box>
      )}
      <Box className={classes.overlay}>
        <Box className={classes.text}>{t("commons.preview")}</Box>
      </Box>
    </Box>
  ));

  React.useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Box
        variant="raised"
        component="span"
        sx={{
          cursor: "pointer",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: { md: "500px", mobile: "100%" },
          transition: "all 0.5s ease-in-out",
          "&:hover": {
            transform: "scale(1.02)",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {uploading && <Busy.Indicator />}
          {!uploading && (
            <Box {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Paper
                sx={{
                  backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
                  },
                }}
              >
                <Box
                  sx={{
                    p: { md: "33px", mobile: "15px" },
                    display: "flex",
                    width: { md: "454px", mobile: "100%" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      flex: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        display: {
                          md: "block",
                          mobile: "none",
                          textAlign: "left",
                        },
                      }}
                      variant={"h6"}
                    >
                      {title}
                    </Typography>
                    <Typography
                      sx={{
                        display: {
                          md: "none",
                          mobile: "block",
                          textAlign: "left",
                        },
                      }}
                      variant={"h6"}
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant={"subtitle3"}
                      sx={{
                        mt: { md: "10px", mobile: "0px" },
                        textAlign: "left",
                      }}
                    >
                      {subtitle}
                    </Typography>
                  </Box>
                  <Box sx={{ color: "#944DFF", flex: 1 }}>
                    <FileUploadIcon
                      sx={{
                        fontSize: { md: "60px", mobile: "50px" },
                        mt: { md: "10px", mobile: "10px" },
                        ml: { md: "0px", mobile: "20px" },
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.thumbsContainer}>{thumbs}</Box>

      <Dialog
        fullWidth
        onClose={() => setShowPreviewFile(null)}
        open={showPreviewFile}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "max-content",
          },
        }}
        PaperProps={{
          maxWidth: "1200px",
        }}
      >
        {showPreviewFile && (
          <>
            {showPreviewFile?.type?.startsWith("image") && (
              <Box
                component={"img"}
                sx={{
                  maxHeight: { md: "600px", mobile: "auto" },
                  width: { md: "auto", mobile: "100%" },
                  margin: "0 auto",
                }}
                src={showPreviewFile.preview}
              />
            )}
            {showPreviewFile?.type?.startsWith("video") && (
              <video
                playsInline
                loop
                autoPlay="autoplay"
                style={{
                  maxHeight: { md: "600px", mobile: "auto" },
                  width: { md: "auto", mobile: "100%" },
                  margin: "0 auto",
                }}
              >
                <source src={showPreviewFile.preview} />
              </video>
            )}
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default Dropzone;
