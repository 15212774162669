import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import paypalLogo from "../../../../static/paypal.png";
import stripeLogo from "../../../../static/stripe.png";
import cryptoLogo from "../../../../static/crypto.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MaterialAccordion from "@mui/material/Accordion";
import Utility from "../../proposal/collection/extraUtilities";
import PaypalDialog from "./PaypalDialog";
import StripeDialog from "./StripeDialog";
import CryptoDialog from "./CryptoDialog";
import { useUserRole } from "../../context";
import CustomHooks from "../../../../@components/hooks";
import { useTranslation } from "react-i18next";

const Index = ({ DAO, refetch }) => {
  const { t } = useTranslation();
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: { lg: "70px", md: "50px", tablet: "15px", mobile: "10px" },
          pb: "0px",
          pt: { lg: "30px", md: "10px", mobile: "0px" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant={"h3"}>
            {t("brands.settings.payment.title")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: { lg: "5px 70px", md: "5px 50px", tablet: "0 15px", mobile: "0 10px" } }}>
        <Typography variant={"subtitle2"} sx={{ mb:  { lg: "35px", md: "30px", mobile: "25px" }  }}>
          {t("brands.settings.payment.subtitle")}
        </Typography>
        {/* 
        <MaterialAccordion
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
          }}
        >
          <AccordionSummary
            sx={{
              height: "80px",
              padding: { md: "18px 30px", mobile: "15px" },
            }}
            expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.text.primary }} />}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    component={"img"}
                    src={paypalLogo}
                    sx={{ width: "45px" }}
                  />
                  <Typography variant={"h6"} sx={{ ml: "15px" }}>
                    Paypal
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: { md: "flex", mobile: "none" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "20px",
                  }}
                >
                  <PaypalDialog.Action
                    element={
                      <UI.Button
                        type={"secondary"}
                        title={t("brands.settings.payment.changeSettings")}
                      />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: { md: "none", mobile: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "5px",
                  }}
                >
                  <PaypalDialog.Action
                    element={
                      <UI.Button
                        type={"secondary"}
                        title={t("brands.settings.payment.change")}
                      />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: { md: "15px  35px", mobile: "15px  15px" },
            }}
          >
            <Utility.AccordionItem
              title={t("brands.settings.payment.clientId")}
              value={DAO?.fiat_payment_info?.paypal?.public_key}
            />
            <Utility.AccordionItem
              title={t("brands.settings.payment.clientSecret")}
              value={DAO?.fiat_payment_info?.paypal?.secret_key}
            />
          </AccordionDetails>
        </MaterialAccordion>
        */}
        <MaterialAccordion
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            mt: "15px",
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            //remove :before
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            sx={{
              height: "80px",
              padding: { md: "18px 30px", mobile: "15px" },
            }}
            expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.text.primary }} />}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex"}}>
                <Box
                  sx={{ display: "flex", alignItems: "center"  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    component={"img"}
                    src={stripeLogo}
                    sx={{ width: "45px" }}
                  />
                  <Typography variant={"h6"} sx={{ ml: "15px" }}>
                    Stripe
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={{ display: "flex" }}
              >
                <Box
                  sx={{
                    display: { md: "flex", mobile: "none" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "20px",
                  }}
                >
                  <StripeDialog.Action
                    element={
                      <UI.Button
                        type={"secondary"}
                        title={t("brands.settings.payment.changeSettings")}
                      />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
                <Box
                  sx={{
                    display: { md: "none", mobile: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "5px",
                  }}
                >
                  <StripeDialog.Action
                    element={
                      <UI.Button
                        type={"secondary"}
                        title={t("brands.settings.payment.change")}
                      />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: { md: "15px  35px", mobile: "15px  15px" },
            }}
          >
            <Utility.AccordionItem
              title={t("brands.settings.payment.publicKey")}
              value={DAO?.fiat_payment_info?.stripe?.public_key}
            />
            <Utility.AccordionItem
              title={t("brands.settings.payment.secretKey")}
              value={DAO?.fiat_payment_info?.stripe?.secret_key}
            />
          </AccordionDetails>
        </MaterialAccordion>
        <MaterialAccordion
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            mt: "15px",
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            sx={{
              height: "80px",
              padding: { md: "18px 30px", mobile: "15px" },
            }}
            expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.text.primary  }} />}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex"}}>
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    component={"img"}
                    src={cryptoLogo}
                    sx={{ width: "45px" }}
                  />
                  <Typography variant={"h6"} sx={{ ml: "15px" }}>
                    Crypto
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={{ display: "flex" }}
              >
                <Box
                  sx={{
                    display: { md: "flex", mobile: "none" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "20px",
                  }}
                >
                  <CryptoDialog.Action
                    element={
                      <UI.Button
                        type={"secondary"}
                        title={t("brands.settings.payment.changeSettings")}
                      />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
                <Box
                  sx={{
                    display: { md: "none", mobile: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: "5px",
                  }}
                >
                  <CryptoDialog.Action
                    element={
                      <UI.Button
                        type={"secondary"}
                        title={t("brands.settings.payment.change")}
                      />
                    }
                    DAO={DAO}
                    onSuccess={() => refetch()}
                  />
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: { md: "15px  35px", mobile: "15px  15px" },
            }}
          >
            <Utility.AccordionItem
              title={t("brands.settings.payment.walletAddress")}
              value={DAO?.treasury_address}
            />
          </AccordionDetails>
        </MaterialAccordion>
      </Box>
    </Box>
  );
};

export default Index;
