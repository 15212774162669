// useFileUpload.js

import { useSnackbar } from "notistack";
import axios from "axios";

const useIPFS = () => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Prepares NFT URI by creating a JSON file and uploading it to the PHP backend.
   * @param {Object} form - The form data containing NFT details.
   * @param {Object} imageData - The image data including name and URL.
   * @param {Object} DAO - The DAO object containing its ID.
   * @returns {string|null} - The URL of the uploaded JSON file or null if failed.
   */
  const prepareNFTUri = async (form, imageData, DAO) => {
    const description =
      form.basic_info.description +
      `\n\n ----- \n\n Visit this collection on Qvrse: https://qvrse.io/brand/${DAO.id}`;

    // Construct the NFT metadata JSON
    const data = {
      name: imageData.name,
      description: description,
      image: imageData.image_url, // URL from PHP upload
      animation_url: form.basic_info.nft_animation_url,
      external_url: `${
        window.env.CHAIN === "AMOY"
          ? "https://mumbai.qvrse.io"
          : "https://qvrse.io"
      }/brand/${DAO.id}`,
      attributes: [
        {
          trait_type: "Utility",
          value: form.main_utility,
        },
        ...form.extra_utilities.map((utility) => ({
          trait_type: "Utility",
          value: utility.name,
        })),
      ],
    };

    try {
      // Convert JSON data to a Blob
      const blob = new Blob([JSON.stringify(data)], { type: "application/json" });

      // Create a File from the Blob
      const jsonFile = new File([blob], `${imageData.name}.json`, {
        type: "application/json",
      });

      // Prepare FormData
      const formData = new FormData();
      formData.append("file", jsonFile);

      // Upload JSON file to PHP backend
      const response = await axios.post("https://files.qvrse.io/upload.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Log the response for debugging
      console.log("NFT JSON Upload Response:", response.data);

      if (response.data.success) {
        const url = response.data.fileUrl; // URL to the uploaded JSON file
        enqueueSnackbar("NFT JSON uploaded successfully!", { variant: "success" });
        return url;
      } else {
        enqueueSnackbar(response.data.message || "Error uploading NFT JSON", {
          variant: "error",
        });
        return null;
      }
    } catch (error) {
      enqueueSnackbar(error.message || "Error uploading NFT JSON", {
        variant: "error",
      });
      return null;
    }
  };

  /**
   * Prepares Collection NFT URI by creating a JSON file and uploading it to the PHP backend.
   * @param {Object} form - The form data containing collection NFT details.
   * @param {Object} DAO - The DAO object containing its ID.
   * @returns {string|null} - The URL of the uploaded JSON file or null if failed.
   */
  const prepareCollectionNFTUri = async (form, DAO) => {
    const description =
      form.basic_info.description +
      `\n\n ----- \n\n Visit this collection on Qvrse: https://qvrse.io/brand/${DAO.id}`;

    // Construct the Collection NFT metadata JSON
    const data = {
      name: form.basic_info.name,
      description: description,
      image: form.basic_info.picture_url.ipfs, // Update to use new image URL from PHP upload
      animation_url: form.basic_info.nft_animation_url,
      external_url: `${
        window.env.CHAIN === "AMOY"
          ? "https://mumbai.qvrse.io"
          : "https://qvrse.io"
      }/brand/${DAO.id}`,
      attributes: [
        {
          trait_type: "Utility",
          value: form.main_utility,
        },
        ...form.extra_utilities.map((utility) => ({
          trait_type: "Utility",
          value: utility.name,
        })),
      ],
    };

    try {
      // Convert JSON data to a Blob
      const blob = new Blob([JSON.stringify(data)], { type: "application/json" });

      // Create a File from the Blob
      const jsonFile = new File([blob], `${form.basic_info.name}.json`, {
        type: "application/json",
      });

      // Prepare FormData
      const formData = new FormData();
      formData.append("file", jsonFile);

      // Upload JSON file to PHP backend
      const response = await axios.post("https://files.qvrse.io/upload.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Log the response for debugging
      console.log("Collection NFT JSON Upload Response:", response.data);

      if (response.data.success) {
        const url = response.data.fileUrl; // URL to the uploaded JSON file
        enqueueSnackbar("Collection NFT JSON uploaded successfully!", { variant: "success" });
        return url;
      } else {
        enqueueSnackbar(response.data.message || "Error uploading Collection NFT JSON", {
          variant: "error",
        });
        return null;
      }
    } catch (error) {
      enqueueSnackbar(error.message || "Error uploading Collection NFT JSON", {
        variant: "error",
      });
      return null;
    }
  };

  return {
    prepareNFTUri,
    prepareCollectionNFTUri,
  };
};

export default useIPFS;
