import React from "react";
import Collection from "./collection";
import Animation from "./animation";
import Discount from "./discount";
import Code from "./code";
import Spatial from "./spatial";
import Video from "./video";
import Text from "./text";
import Image from "./image";
import Game from "./Game";
import AllCollection from "./allCollection";
import Grid from "@mui/material/Grid";
import { Box } from "@material-ui/core";
import UI from "../../../../../@components/UI";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../../../@components/userContext";

const CardItem = ({ DAO, item, onDrop, onFilterCollections, refetch }) => {
  const { user: loggedInUser } = useUserContext();
  return (
    <Grid
      item
      mobile={4}
      tablet={4}
      md={3}
      lg={3}
      xl={2.4}
      xxl={2}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          width: {md: "100%", tablet: "94%", mobile: "95%"}, 
          height: "auto",
          aspectRatio: "1",
          maxHeight: "100vh"
        }}
      >
        {item.album_space_type === "Collection" &&
          item?.collections?.length === 1 && (
            <>
              {item.is_locked === false && loggedInUser && (
                <Collection.Card
                  refetch={refetch}
                  data={item}
                  DAO={DAO}
                  onDrop={onDrop}
                  item={item}
                  onSelect={(collectionIds) =>
                    onFilterCollections(collectionIds)
                  }
                />
              )}
              {(item.is_locked === true || !loggedInUser) && (
                <Collection.Locked.Action
                  element={<Collection.Card onDrop={onDrop} item={item} />}
                />
              )}
            </>
          )}
        {item.album_space_type === "Collection" &&
          item?.collections?.length > 1 && (
            <>
              {item.is_locked === false && loggedInUser && (
                <AllCollection.Card
                  refetch={refetch}
                  data={item}
                  DAO={DAO}
                  onDrop={onDrop}
                  item={item}
                  onSelect={(collectionIds) =>
                    onFilterCollections(collectionIds)
                  }
                />
              )}
              {(item.is_locked === true || !loggedInUser) && (
                <AllCollection.Locked.Action
                  element={<AllCollection.Card onDrop={onDrop} item={item} />}
                />
              )}
            </>
          )}
        {item.album_space_type === "Discount" && (
          <>
            {item.is_locked === false && (
              <Discount.Card
                refetch={refetch}
                data={item}
                DAO={DAO}
                onDrop={onDrop}
                item={item}
              />
            )}
            {item.is_locked === true && (
              <Discount.Locked.Action
                element={<Discount.FrontSide onDrop={onDrop} item={item} />}
              />
            )}
          </>
        )}
        {item.album_space_type === "Spatial" && (
          <>
            {item.is_locked === false && loggedInUser && (
              <Spatial.Card
                refetch={refetch}
                data={item}
                DAO={DAO}
                onDrop={onDrop}
                item={item}
              />
            )}
            {(item.is_locked === true || !loggedInUser) && (
              <Spatial.Locked.Action
                element={<Spatial.FrontSide onDrop={onDrop} item={item} />}
              />
            )}
          </>
        )}
        {item.album_space_type === "Code" && (
          <>
            {item.is_locked === false && loggedInUser && (
              <Code.Card
                refetch={refetch}
                data={item}
                DAO={DAO}
                onDrop={onDrop}
                item={item}
              />
            )}
            {(item.is_locked === true || !loggedInUser) && (
              <Code.Locked.Action
                element={<Code.Card onDrop={onDrop} item={item} />}
              />
            )}
          </>
        )}
        {item.album_space_type === "Video" && (
          <>
            {item.is_locked === false && loggedInUser && (
              <Video.Card
                refetch={refetch}
                data={item}
                DAO={DAO}
                onDrop={onDrop}
                item={item}
              />
            )}
            {(item.is_locked === true || !loggedInUser) && (
              <Video.Locked.Action
                element={<Video.FrontSide onDrop={onDrop} item={item} />}
              />
            )}
          </>
        )}
        {item.album_space_type === "Animation" && (
          <>
            {item.is_locked === false && loggedInUser && (
              <Animation.Card
                refetch={refetch}
                data={item}
                DAO={DAO}
                onDrop={onDrop}
                item={item}
              />
            )}
            {(item.is_locked === true || !loggedInUser) && (
              <Animation.Locked.Action
                element={<Animation.FrontSide onDrop={onDrop} item={item} />}
              />
            )}
          </>
        )}
        {item.album_space_type === "Text" && (
          <Box sx={{ height: "100%", width: "100%" }}>
            {item.is_locked === false && loggedInUser && (
              <Text.Card
                refetch={refetch}
                data={item}
                DAO={DAO}
                onDrop={onDrop}
                item={item}
              />
            )}
            {(item.is_locked === true || !loggedInUser) && (
              <Text.Locked.Action
                element={<Text.FrontSide onDrop={onDrop} item={item} />}
              />
            )}
          </Box>
        )}
        {item.album_space_type === "Image" && (
          <Box sx={{ height: "100%", width: "100%" }}>
            {item.is_locked === false && loggedInUser && (
              <Image.Card
                refetch={refetch}
                data={item}
                DAO={DAO}
                onDrop={onDrop}
                item={item}
              />
            )}
            {(item.is_locked === true || !loggedInUser) && (
              <Image.Locked.Action
                element={<Image.FrontSide onDrop={onDrop} item={item} />}
              />
            )}
          </Box>
        )}
        {item.album_space_type === "game" && <Game item={{ id: item.id }} />}
        {item.album_space_type === "allCollection" && (
          <AllCollection.Dialog.Action
            element={<AllCollection.Card item={{ id: item.id }} />}
          />
        )}
      </Box>
    </Grid>
  );
};

const List = ({ DAO, items, handleDrop, onFilterCollections, refetch }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      columnSpacing={{ mobile: 0, md: 3 }}
      rowSpacing={{ mobile: 1, md: 3 }}
      justifyContent="stretch"
    >
      {items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <UI.NoResults text={t("brands.albums.cards.albumEmpty")} />
        </Box>
      )}
      {items?.length > 0 &&
        items.map((item, index) => {
          if (index === 0) {
            item.is_locked = false;
          }
          return (
            <CardItem
              refetch={refetch}
              onFilterCollections={onFilterCollections}
              DAO={DAO}
              item={item}
              key={item.id}
              onDrop={(item) => handleDrop(item.id, item)}
            />
          );
        })}
    </Grid>
  );
};
export default List;
