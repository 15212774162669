import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Busy from "./busy";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon from MUI
import { makeStyles } from "@mui/styles";
import pdfIcon from "../../static/pdf-upload.png";
import aiIcon from "../../static/ai-icon-2.png";
import TextField from "@mui/material/TextField"; // Import TextField from MUI
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useSnackbar } from "notistack";
import axios from "axios";
import confirmIcon from "../../static/confirm-icon.png";
import rejectIcon from "../../static/reject-icon.png";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "28px",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  description: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
}));

const AvatarPreview = ({ url, type, width, height }) => {
  return (
    <Box
      sx={{
        width: width ? width : { md: "250px", mobile: "200px" },
        marginBottom: "20px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        height: height ? height : { md: "250px", mobile: "200px" },
        overflow: "hidden",
        borderRadius: "50%",
        border: "2px solid transparent",
        "&:hover": {
          boxShadow: "#944dff 0px 0px 15px 0px",
          border: "2px solid #944dff",
          transform: "scale(1.1)",
          transition: "all 0.2s ease 0s",
        },
      }}
    >
      {type === "image" && (
        <img
          style={{
            width: "100%",
            height: "100%",
            margin: "0 auto",
            textAlign: "center",
            objectFit: "cover",
          }}
          src={url}
          alt={"dao-logo"}
        />
      )}
      {type === "video" && (
        <video
          playsInline
          width="auto"
          height={"270px"}
          controls
          controlsList="nodownload"
        >
          <source src={url} />
        </video>
      )}
    </Box>
  );
};

const ConfirmRejectButtons = ({ onConfirm, onReject }) => {
  const { t } = useTranslation();
  return (
    <InputAdornment position="end">
      <Tooltip title={t("actions.confirmPrompt")} placement="bottom" arrow>
        <IconButton
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
          aria-label="confirm"
          onClick={onConfirm}
          edge="end"
        >
          <Box
            component={"img"}
            src={confirmIcon}
            alt="Confirm"
            sx={{
              filter: (theme) =>
                theme.palette.type === "dark" ? "invert(1)" : "none",
              width: "20px",
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("actions.closeAI")} placement="bottom" arrow>
        <IconButton
          sx={{ color: (theme) => theme.palette.text.primary }}
          aria-label="reject"
          onClick={onReject}
          edge="end"
        >
          <Box
            component={"img"}
            src={rejectIcon}
            alt="Reject"
            sx={{
              filter: (theme) =>
                theme.palette.type === "dark" ? "invert(1)" : "none",
              width: "20px",
            }}
          />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};

const Upload = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    id,
    onChange,
    name,
    defaultValue,
    acceptFiles,
    avatarPreviewWidth,
    avatarPreviewHeight,
    previewHeight = "280px",
    noHeight,
    noPadding,
    showAvatarPreview = false,
    aiButtonEnabled,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadData, setUploadData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isPromptActive, setIsPromptActive] = useState(false);
  const [promptValue, setPromptValue] = useState("");
  const [previousValue, setPreviousValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const maxFileSize = 25 * 1048576; // 25MB

  useEffect(() => {
    defaultValue?.ipfs && defaultValue?.file && setUploadData(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    uploadData && onChange(uploadData);
  }, [uploadData, onChange]);

  const fetchAIResult = async (prompt) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://backend.qvrse.io/ai_image_prompt/${prompt}/`
      );
      return response.data;
    } catch (error) {
      enqueueSnackbar(error.message || "Error fetching AI result", {
        variant: "error",
      });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleAIClick = () => {
    setPreviousValue(uploadData);
    setUploadData(null);
    setPromptValue("");
    setIsPromptActive(true);
  };

  const handlePromptChange = (event) => {
    setPromptValue(event.target.value);
  };

  const handleSubmit = async () => {
    const url = await fetchAIResult(promptValue);
    if (url) {
      setUploadData({ ipfs:url, file: { type: "image/" } });
    } else {
      setUploadData(previousValue);
    }
    setIsPromptActive(false);
  };

  const handleReject = () => {
    setUploadData(previousValue);
    setIsPromptActive(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isPromptActive) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const onUpload = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    if (file) {
      setUploadData(null);
      if (file.size > maxFileSize) {
        enqueueSnackbar(`The file is too large! Maximum file size: 25MB`, {
          variant: "error",
        });
        setUploading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append("file", file);
  
      try {
        const response = await axios.post("https://files.qvrse.io/upload.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  
        if (response.data.success) {
          const url = response.data.fileUrl; // Ensure your PHP returns the file URL as 'fileUrl'
          setUploadData({ ipfs: url, file });
        } else {
          enqueueSnackbar(response.data.message || "Error uploading file", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(error.message || "Error uploading file", {
          variant: "error",
        });
      } finally {
        setUploading(false);
      }
    } else {
      setUploading(false);
    }
  };
  

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadData(null);
  };

  return (
    <Box
      sx={{
        maxWidth: "500px",
        height: isPromptActive ? "200px" : "auto",
        margin: "0 auto",
      }}
    >
      {isPromptActive ? (
        <Box sx={{ width: "100%", p: "17px" }}>
          <TextField
            multiline
            rows={6}
            variant="outlined"
            value={promptValue}
            onChange={handlePromptChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: isPromptActive ? (
                <Box
                  sx={{
                    background: "#944dff",
                    borderRadius: "5px",
                    p: "5px 10px",
                    color: "#fff",
                    mr: "3px",
                  }}
                >
                  <Typography
                    sx={{ color: "inherit", fontWeight: "600" }}
                    variant={"subtitle1"}
                  >{`${t("commons.prompt")}`}</Typography>
                </Box>
              ) : null,
              endAdornment: (
                <>
                  {!isLoading && (
                    <ConfirmRejectButtons
                      onConfirm={handleSubmit}
                      onReject={handleReject}
                    />
                  )}
                  {isLoading && (
                    <Busy.Indicator width={"40px"} height={"40px"} />
                  )}
                </>
              ),
            }}
            sx={{
              width: "100%",
              borderRadius: (theme) => theme.borderRadius.secondary,
              backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            }}
          />
        </Box>
      ) : (
        <Box sx={{ position: "relative" }}>
          <input
            ref={ref}
            name={name}
            accept={acceptFiles}
            style={{ display: "none" }}
            id={id}
            onChange={onUpload}
            type="file"
          />
          <label htmlFor={id} style={{ width: "100px", height: "300px" }}>
            <Box
              variant="raised"
              component="span"
              sx={{
                position: "relative",
                cursor: "pointer",
                padding: noPadding ? "0px" : "15px",
                borderRadius: (theme) => theme.borderRadius.primary,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                height: noHeight ? "auto" : { md: "300px", mobile: "220px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {uploading && <Busy.Indicator />}
                {!uploadData && !uploading && (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%", // Adjusted to 100% to match the container width
                      maxWidth: "500px",
                      borderRadius: (theme) => theme.borderRadius.primary,
                      p: "17px",
                      transition: "all 0.5s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    {aiButtonEnabled && (
                      <Tooltip
                        title={t("actions.aiButton")}
                        placement="bottom"
                        arrow
                      >
                        <Box
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAIClick();
                          }}
                          component={"img"}
                          src={aiIcon}
                          alt="ai icon"
                          sx={{
                            position: "absolute",
                            top: { md: "30px", mobile: "25px" },
                            right: { md: "30px", mobile: "25px" },
                            width: { md: "32px", mobile: "28px" }
                          }}
                        />
                      </Tooltip>
                    )}
                    <Paper
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.bgTransparent.primary,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.bgTransparent.secondary,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          p: { md: "33px", mobile: "15px 15px 17px" },
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            flex: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              display: {
                                md: "block",
                                mobile: "none",
                              },
                              textAlign: "left",
                            }}
                            variant={"h4"}
                          >
                            {title}
                          </Typography>
                          <Typography
                            sx={{
                              display: {
                                md: "none",
                              },
                              mobile: "block",
                              textAlign: "left",
                            }}
                            variant={"h4"}
                          >
                            {title}
                          </Typography>
                          {!subtitle && (
                            <Typography
                              variant={"subtitle2"}
                              sx={{ textAlign: "left" }}
                            >
                              Click here to select a file.
                            </Typography>
                          )}
                          {subtitle && (
                            <Typography
                              variant={"subtitle3"}
                              sx={{
                                mt: { md: "10px", mobile: "0px" },
                                textAlign: "left",
                              }}
                            >
                              {subtitle}
                            </Typography>
                          )}
                        </Box>
                        <Box sx={{ color: "#944DFF", flex: 1 }}>
                          <FileUploadIcon
                            sx={{
                              fontSize: "80px",
                              pt: { md: "0px", mobile: "15px" },
                              pl: { md: "0px", mobile: "10px" },
                            }}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                )}
                {uploadData &&
                  uploadData.ipfs &&
                  uploadData.file &&
                  uploadData.file.type?.startsWith("image/") &&
                  (showAvatarPreview ? (
                    <Box sx={{ position: "relative" }}>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          zIndex: 1,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        onClick={handleRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                      <AvatarPreview
                        url={uploadData.ipfs}
                        type={"image"}
                        width={avatarPreviewWidth}
                        height={avatarPreviewHeight}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ position: "relative" }}>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          zIndex: 1,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        onClick={handleRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                      <img
                        alt={"file-preview"}
                        style={{ height: previewHeight, borderRadius: "8px" }}
                        src={uploadData.ipfs}
                      />
                    </Box>
                  ))}
                {uploadData &&
                  uploadData.ipfs &&
                  uploadData.file &&
                  uploadData.file.type?.startsWith("video/") &&
                  (showAvatarPreview ? (
                    <Box sx={{ position: "relative" }}>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          zIndex: 1,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        onClick={handleRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                      <AvatarPreview url={uploadData.ipfs} type={"video"} />
                    </Box>
                  ) : (
                    <Box sx={{ position: "relative" }}>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          zIndex: 1,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        onClick={handleRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                      <video
                        playsInline
                        width="auto"
                        height={"270px"}
                        controls
                        controlsList="nodownload"
                        sx={{
                          borderRadius: (theme) => theme.borderRadius.primary,
                        }}
                      >
                        <source src={uploadData.ipfs} />
                      </video>
                    </Box>
                  ))}
                {uploadData &&
                  uploadData.ipfs &&
                  uploadData.file &&
                  uploadData.file.type === "application/pdf" && (
                    <Box sx={{ position: "relative" }}>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          zIndex: 1,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                          },
                        }}
                        onClick={handleRemove}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Box>
                        <Box
                          component={"img"}
                          src={pdfIcon}
                          sx={{ width: "50px" }}
                        />
                        <Box className={classes.description}>
                          {uploadData.file?.name}
                        </Box>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          </label>
        </Box>
      )}
    </Box>
  );
});

export default Upload;
